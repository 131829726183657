import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase';


const firebaseConfig = {
  apiKey: "AIzaSyBkbLSfDC509nIJ5sc2Hc2KAjeS9ZrRtWM",
  authDomain: "accelerator-platform-1909.firebaseapp.com",
  databaseURL: "https://accelerator-platform-1909.firebaseio.com",
  projectId: "accelerator-platform-1909",
  storageBucket: "accelerator-platform-1909.appspot.com",
  messagingSenderId: "320842759139",
  appId: "1:320842759139:web:5a155adcd24c7b436ffed6",
  measurementId: "G-H1XWH1NXQP"
};

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
