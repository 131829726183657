import React from 'react';
import './CycleMenu.scss';


function CycleMenu({ cycles, clickHandler }){
    // console.log('cycles in menu option');
    // console.log(cycles.data);
    console.log('Rendering the menu component')

    //Dynamically load the menu
    const cycleOptions = cycles.data.map(cycle=>{
        return(
            <div className="cycle-tab" key={ cycle } onClick={ ()=>{clickHandler(cycle)} } >{ cycle }</div>
        )
    })


    return(
        <div className="cycles-container">
            { cycleOptions }
        </div>
    )
}

export default CycleMenu;