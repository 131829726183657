import React from 'react'
import Header from '../../components/Header/Header'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import './Karma.scss'


function Karma({ setAuthState }){

    return(
        <div className="karma-page">
            <Header setAuthState={ setAuthState } />
            <div className="karma-body">
                <SectionHeader header='Karma Information' />
            </div>
        </div>
    )
}

export default Karma