import React, { useState, useEffect } from 'react';
import CenterModal from '../../components/CenterModal/CenterModal'
import './RubricModal.scss';
import * as firebase from 'firebase';
import ExitIcon from '../../assets/exit-icon.svg'

function RubricModal({ cycle, evaluations, setVisibleModal, setEvaluations }){
    const [ formDisplay, setFormDisplay ] = useState(false);
    const [ confirmModalDisplay, setConfirmModalDisplay ] = useState(false);
    const [ deleteRubricId, setDeleteRubricId ] = useState('');
    
    //Hide all confirmation modals
    const hideConfirmationModal = () =>{
        setConfirmModalDisplay(false)
    }

    const showKeyConfirmation = (rubricId) => {
        setConfirmModalDisplay(true)
        setDeleteRubricId(rubricId)
    }

    //Exit the modal
    const handleExitClick = () => {
        setVisibleModal(false);
    }

    //Show form
    const showForm = () => {
        setFormDisplay(true);
    }

    //Add a new evaluation to the list
    const handleSubmit = (event) => {
        event.preventDefault();
        const newEvaluation = event.target.newRubric.value;
        const newEvaluationSet = [...evaluations, newEvaluation];
        setEvaluations([...newEvaluationSet]);
        setFormDisplay(false);
        const setRubric = firebase.functions().httpsCallable('setRubric');
        setRubric({
            'cycle': cycle,
            'newEvaluation': newEvaluation
        })
        .then(result=>{
            console.log(result);
        })
        .catch(error=>{
            console.log(error);
        })
        console.log(evaluations);
    }

    //Handler to eliminate rubric
    const eliminateRubricHandler = (rubricID, targetCycle) => {
        setConfirmModalDisplay(false);
        setDeleteRubricId(rubricID);
        const eliminateRubric = firebase.functions().httpsCallable('eliminateRubric');
        console.log(`Calling the function to eliminate the rubric`,targetCycle, rubricID)
        eliminateRubric({
            'cycle': targetCycle,
            'targetEvaluation': rubricID
        })
        .then(response=>{
            console.log(response)
            let updatedEvaluations = [...evaluations]
            const targetIndex = updatedEvaluations.indexOf(rubricID)
            updatedEvaluations.splice(targetIndex,1)
            setEvaluations([...updatedEvaluations])
        })
        .catch(error=>{
            console.log(error)
        })
    }

    //Handle cancel Rubric elimination
    const abortRubricElimination = () => {
        setConfirmModalDisplay(false);
        setDeleteRubricId('');
    }

    const evaluationList = evaluations.map(evaluation=>{
        if(evaluation !== 'name'){
            return(
                <div className="evaluation-container" key = { evaluation }>
                    <p>{evaluation}</p>
                    <img onClick={ ()=>{showKeyConfirmation(evaluation)} } src={ ExitIcon } className="eliminate-rubric-btn" />
                </div>
            )
        }
    })

    return(
        <div className="modal-background">
            <div className="modal">
                {
                    confirmModalDisplay
                        ? <CenterModal deleteRubricId = { deleteRubricId } title='Are you sure you want to eliminate this rubric?' confirmTrigger = { ()=>{eliminateRubricHandler(deleteRubricId, cycle)} } cancelTrigger = { abortRubricElimination } />
                        : null
                }
                <img onClick={ handleExitClick } className="exit-modal" src={ ExitIcon } />
                <h4>Edit the rubric for { cycle }</h4>
                { evaluationList }
                {
                    formDisplay
                        ? (
                            <>  
                            <p className="format-text">No spaces or special characters</p>
                                <form onSubmit = { handleSubmit } className="input-container">
                                    <input type="text" name="newRubric" id="newRubric" pattern="^(\d|\w)+$" />
                                    <input type="submit" value="+" id="add-new-rubric"/>
                                </form>
                                
                            </>
                        )
                        : <button onClick = { showForm } className="add-btn">Add Rubric</button>
                }
                
            </div>
        </div>
    )
}

export default RubricModal;