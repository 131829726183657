import React from 'react'
import './UserCard.scss'


function UserCard({ name, company, id, actionHandler, buttonText, btnClass }){
    return(
        <div className="user-card">
            <div key = { id } className="user-information">
                <h4>{ name }</h4>
                <p>{ company }</p>
            </div>
            <button 
                onClick={()=>{actionHandler(id)}} 
                //className="disable-btn"
                className={ btnClass === 'disableBtnClass' ? 'disable-btn' : 'enable-btn' }>
                    { buttonText }
            </button>
        </div>
    )
}

export default UserCard