import React, { useState } from 'react'
import * as firebase from 'firebase'
import Modal from '../../components/Modal/Modal'
import logo from '../../assets/1909-logo.png'

import './Login.scss'

function Login({ setUserAuth }){
    const [visibleModal,setVisibleModal] = useState(false)
    const [loginStatus, setLoginStatus] = useState('Log In')
    const [modalContent, setModalContent ] = useState({
        'title': '',
        'meesage': ''
    })

    const loginHandler = () => {
        setLoginStatus('Loading...')

        const provider = new firebase.auth.GoogleAuthProvider();

        firebase.auth().signInWithPopup(provider)
            .then(function(result) {
                // const user = result.user;

                //Check if the user is an admin
                const adminCheck = firebase.functions().httpsCallable('adminCheck');
                adminCheck().then(result=>{
                    if(result.data){
                        setUserAuth(true);
                        setLoginStatus('Redirecting...');
                    }
                    else{
                        setVisibleModal(true);
                        setModalContent({
                            'title': 'You are not an admin',
                            'message': `It seems you're not an admin. If you think this is a mistake, contact andres@createwithpixel.com`
                        })
                        setLoginStatus('Log In');
                    }
                })
                .catch(error=>{
                    console.log(`Error in Admin Check`)
                    console.log(error)
                    setVisibleModal(true);
                    setModalContent({
                        'title': 'Error in Authentication',
                        'message': `Oops, looks like something went wrong. Please try again`
                    })
                })
            })
            .catch(error =>{
                console.log(error);
                setVisibleModal(true);
                setModalContent({
                    'title': 'Error in Authentication',
                    'message': `Oops, looks like something went wrong. Please try again`
                })
            })
            
        
    }

    return(
        <div className="login-page">
            { visibleModal 
                ? <Modal setVisibleModal={ setVisibleModal } title={ modalContent.title } message = { modalContent.message } />
                : null 
            }
            <img src={logo} alt='1909 coworking space logo'/>
            <div className="login-container">
                <h3>Admin Login</h3>
                <p>This site is for 1909 accelerator admins only. Your user credentials will be stored if there is a login attempt</p>
                <button className="ghost-button" onClick={loginHandler}>{ loginStatus }</button>
            </div>
        </div>
    )
}

export default Login;