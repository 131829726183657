import React, { useState, useEffect } from 'react'
import * as firebase from 'firebase'
import Header from '../../components/Header/Header'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import UserContainer from '../../components/UserContainer/UserContainer'
import Loader from '../../components/Loader/Loader'
import Modal from '../../components/Modal/Modal'
import './Participants.scss'

function Participants({ setUserAuth }){
    const [activeUsers, setActiveUsers] = useState([])
    const [disabledUsers, setDisabledUsers] = useState({})
    const [displayModal, setDisplayModal] = useState(false)
    const [modalContent, setModalContent] = useState({
        'title': '',
        'message': ''
    })
    const [fetchingUsers, setFetchingUsers] = useState(true)

    const getAllUsers = async () =>{
        try{
            //GET THE ACTIVE USERS FROM THE DATABASE
            const getActiveUsers = firebase.functions().httpsCallable('getActiveUsers')
            const active = await getActiveUsers()   
            console.log('Active Users:',active.data)
            setActiveUsers({...active.data})
            setFetchingUsers(false)
            console.log('active users:', activeUsers)


            const getDisabledUsers = firebase.functions().httpsCallable('getDisabledUsers')
            const disabled = await getDisabledUsers()
            console.log('disabled users',disabled.data)
            setDisabledUsers({...disabled.data})

        }
        catch(error){
            setModalContent({
                'title': 'Something went wrong!',
                'message': 'We had some problems retrieving the users. Please try again later'
            })
            setDisplayModal(true)
            console.log(`Error in Cloud Function:`,error)
        }
    }

    useEffect(()=>{
        getAllUsers();
    },[])

    const disableUser = (userId) =>{
        setFetchingUsers(true)
        const disableUsers = firebase.functions().httpsCallable('disableUser');
        disableUsers({
            'uid': userId
        })
        .then(response=>{
            console.log(response)
            let newActiveUsers = {...activeUsers}
            let newDisabledUsers = {...disabledUsers}

            newDisabledUsers[userId] = {...newActiveUsers[userId]}

            delete newActiveUsers[userId]

            setActiveUsers({...newActiveUsers})
            setDisabledUsers({...newDisabledUsers})
            setFetchingUsers(false)
        })
        .catch(error=>{
            console.log(error)
        })
    }

    const enableUser = (userId) =>{
        setFetchingUsers(true)
        const enableUsers = firebase.functions().httpsCallable('enableUser');
        enableUsers({
            'uid': userId
        })
        .then(response=>{
            console.log(response)
            let newActiveUsers = {...activeUsers}
            let newDisabledUsers = {...disabledUsers}

            newActiveUsers[userId] = {...newDisabledUsers[userId]}

            delete newDisabledUsers[userId]

            setActiveUsers({...newActiveUsers})
            setDisabledUsers({...newDisabledUsers})
            setFetchingUsers(false)
        })
        .catch(error=>{
            console.log(error)
        })
    }


    return(
        <div className="participants-page">
            {
                displayModal
                ? <Modal setVisibleModal = { setDisplayModal } title = { modalContent.title } message={ modalContent.message } />
                : null
            }
            <Header setUserAuth = { setUserAuth } />
            <div className="participants-body">
                <SectionHeader header='Participant Management' />
                <div className="user-wrapper">
                    {
                        !fetchingUsers
                        ? (<>
                            <UserContainer title = 'Active Users' usersObject= { activeUsers } actionHandler = { disableUser } buttonText='Disable' btnClass='disableBtnClass' />
                            <UserContainer title = 'Disabled Users' usersObject= { disabledUsers } actionHandler = { enableUser } buttonText='Enable'  btnClass='enableBtnClass' />
                        </>)
                        : <Loader />
                    }
                    {/* <UserContainer title = 'Disabled Users'/> */}
                </div>
            </div>
        </div>
    )
}

export default Participants