import React from 'react';
import { NavLink } from 'react-router-dom';
import './Header.scss';
import * as firebase from 'firebase'

function Header({ setUserAuth }){


    const signOutHandler = () => {
        firebase.auth().signOut().then(response=>{  
            setUserAuth(false);
            console.log(`Sign out successful`);
        }).catch(error=>{
            console.log(error);
        });
    }
    return(
        <nav className = "vertical-header">
            <img src={require('../../assets/logo.png')} alt="1909 logo" />
            <div className="menu-item-container">
                <div className="menu-item">
                    <NavLink exact to = '/' className="menu-text">
                        Evaluate
                    </NavLink>
                </div>
                <div className="menu-item">
                    <NavLink to = '/participants' className="menu-text">
                        Participants
                    </NavLink>
                </div>
                {/* <div className="menu-item">
                    <NavLink to = '/karma' className="menu-text">
                        Karma Points
                    </NavLink>
                </div> */}
            </div>
            <div className="hor-separator"></div>

            <div className="logout-container" onClick={ signOutHandler }>
                <div className="hor-separator"></div>
                <p>Log Out</p>
            </div>
        </nav>

    )
}

export default Header;