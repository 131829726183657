import React from 'react';
import LoaderAnimation from '../../assets/loader.gif';
import './Loader.scss';

function Loader(){
    return(
        <div className="loader-container">
            <img src={require('../../assets/loader.gif')} alt='loader' />
        </div>
    )
}

export default Loader;