import React from 'react';
import './SectionHeader.scss';


function SectionHeader({ header }){
    return(
        <div className="section-header">
            <h2>{ header }</h2>
        </div>

    )
}

export default SectionHeader;