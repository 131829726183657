import React, { useState, useEffect } from 'react';
import RubricModal from '../../components/RubricModal/RubricModal'
import './EvaluationTable.scss';

function EvaluationTable({ userScores, evaluations, selectedCycle, changeHandler, setEvaluations }){
    const [visibleModal, setVisibleModal] = useState(false);
     
    console.log('Rendering the EvaluationTable component')

    const openRubricModal = () =>{
        setVisibleModal(true);
    }

    const tableHeader = evaluations.map(e => {
            return (
                <h4 className="header-item" id={e} key={e}>{ e }</h4>
            )
    })

    const tableBody = Object.keys(userScores).map(participant =>{
        return(
            <div className='table-row' key={ participant }>
                <p key = {`${participant}-name`}>{ userScores[participant]['name'] }</p>
                {
                    evaluations.map(rubric=>{
                        if(rubric!='name'){
                            return(
                                //<p>{ userScores[participant][rubric] ? 'true' : 'false' }</p>
                                <div className="input-box" key={`${participant}-${rubric}-container`}>
                                    { userScores[participant][rubric]
                                        ? <input type="checkbox" onChange = { changeHandler } key={`${selectedCycle}-${participant}-${rubric}`} id={`${selectedCycle}-${participant}-${rubric}`} name={`${selectedCycle}-${participant}-${rubric}`} value="true"  checked/>
                                        : <input type="checkbox" onChange = { changeHandler } key={`${selectedCycle}-${participant}-${rubric}`} id={`${selectedCycle}-${participant}-${rubric}`} name={`${selectedCycle}-${participant}-${rubric}`} value="true"  />
                                    }
                                
                                </div>
                            )
                        }
                    })
                }
            </div>
        )
    })

    //Create the table rows
    const tableIterator = () =>{
        Object.keys(userScores).map(key=>{
            return(
                <div className='user-row'>
                    
                </div>
            )
        })
    }
    return(
        <>
            {
                visibleModal
                    ? <RubricModal cycle ={ selectedCycle } evaluations = { evaluations } setVisibleModal={ setVisibleModal } setEvaluations = { setEvaluations }/>
                    : null
            }
            <div className="table-description">
                <h3>{`${selectedCycle} Evaluation Table`}</h3>
                <button onClick={ openRubricModal } className="edit-rubric">Edit Rubric</button>
                
            </div>
            <div className='evaluation-table'>
                <div className='table-header'>
                    { tableHeader }
                </div>
                { tableBody }
            </div>
        </>
    )
}

export default EvaluationTable;