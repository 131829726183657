import React from 'react';

import './CenterModal.scss';

function CenterModal({ deleteRubricId, title, confirmTrigger, cancelTrigger }){


    return(
        <div className="confirmation-modal-background">
            <div className="confirmation-modal-container">
                <h3>{ title }</h3>
                <p>{ deleteRubricId }</p>
                <div className="option-container">
                    <button onClick={ confirmTrigger } className="primary-button">Confirm</button>
                    <button onClick={ cancelTrigger } className="cancel-button">Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default CenterModal