import React, {useState, useEffect } from 'react'
import * as firebase from 'firebase'
import Header from '../../components/Header/Header'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import CycleMenu from '../../components/CycleMenu/CycleMenu'
import Modal from '../../components/Modal/Modal'
import Loader from '../../components/Loader/Loader'
import EvaluationTable from '../../components/EvaluationTable/EvaluationTable'
import './Evaluation.scss'



function Evaluation({ setUserAuth }){

    //set the states for the evaluation page
    const [visibleModal, setVisibleModal] = useState(false);
    const [modalInformation, setModalInformation] = useState({});
    const [userScores,setUserScores] = useState();
    const [evaluations, setEvaluations] = useState();
    const [fetchingUsers, setFetchingUsers] = useState(true);
    const [fetchingCycles, setFetchingCycles] = useState(true);
    const [cycles, setCycles] = useState([]);
    const [selectedCycle, setSelectedCycle] = useState('');
    const [optionSelected, setOptionSelected] = useState(false)

    //Make the call to grab the available cycles
    useEffect(() =>{
        const getCycles = firebase.functions().httpsCallable('getCycles');
        getCycles()
        .then(cycleList=>{
            console.log(cycleList);
            setCycles(cycleList);
            setFetchingCycles(false);
        })
        .catch(error=>{
            console.log(error);
        })
    },[])

    //For dynamically loading the evaluation table
    const menuClickHandler = (clickedCycle) =>{
        setFetchingUsers(true);
        setOptionSelected(true);
        setSelectedCycle(clickedCycle);
        const getPersistenceUsers = firebase.functions().httpsCallable('getPersistenceUsers');
        getPersistenceUsers({ 'id': clickedCycle })
        .then(collection =>{
            collection.data.evaluations.unshift('name');
            setEvaluations(collection.data.evaluations);
            delete collection.data.evaluations;
            setUserScores({...collection.data});
            //console.log(collection.data);
            setFetchingUsers(false);
        })
        .catch(error=>{
            console.log(error);
            setOptionSelected(false);
            setModalInformation({
                title: "Couldn't load cycle table",
                message: 'There was a problem loading the table, please try again or contact the system admin'
            })
            setVisibleModal(true);

        })
        // console.log('Running Menu Click Handler');
    }

    //Submit the changes in the persistence evaluation table to the db
    const changeHandler = async (event) =>{
        let changedField = event.target.id;
        let [ cycle, userEmail, rubric ] = changedField.split('-');
        let changedValue = event.target.checked;
        // console.log('changedField');
        // console.log(`${cycle} / ${userEmail} / ${rubric} / ${changedValue}`);
        // console.log(userScores);
        let newUserScores = userScores;
        console.log('newUserScores'); 
        newUserScores[userEmail][rubric] = changedValue;
        setUserScores({...newUserScores});
        try{
            const setPersistenceChanges = firebase.functions().httpsCallable('setPersistenceChanges');
            const changeConfirmation = await setPersistenceChanges({
                'cycle': cycle,
                'rubric': rubric,
                'userEmail': userEmail,
                'value': changedValue 
            });
            console.log(changeConfirmation);
        }
        catch(error){
            console.error(error, error.message);
        }
        console.log('Running User Change Handler');
    }

    return(
        <div className="evaluation-page">
            <Header setUserAuth = { setUserAuth }/>
            <div className='evaluation-body'>
                <SectionHeader header='Participant Evaluations' />
                {
                    fetchingCycles 
                        ? <Loader />
                        : <CycleMenu cycles = { cycles } clickHandler = { menuClickHandler } />
                }
                {   
                    optionSelected
                        ? fetchingUsers
                            ? <Loader />
                            : <EvaluationTable 
                                userScores = { userScores } 
                                evaluations = { evaluations } 
                                selectedCycle = {selectedCycle} 
                                changeHandler = { changeHandler } 
                                setEvaluations = { setEvaluations }
                            />
                        :<h4 className="evaluation-sign">Please select a cycle to see the evaluation table</h4>
            }
            </div>
        </div>
    )
}

export default Evaluation