import React from 'react';
import './Modal.scss';


const Modal = ({ setVisibleModal, title, message }) =>{

    
    const handleAccept = () =>{
        setVisibleModal(false);
    }
    
    return(
        <div className = "modal-container">
            <h3>{ title }</h3>
            <p>{ message }</p>
            <div className='button-wrapper'>
                <button onClick = { handleAccept } className="accept-button">OK</button>
            </div>
        </div>
    )
}

export default Modal;