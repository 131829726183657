import React, { useState } from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css';
import Login from './scenes/Login/Login';
import Evaluation from './scenes/Evaluation/Evaluation'
import Participants from './scenes/Participants/Participants'
import Karma from './scenes/Karma/Karma'


function App() {

  const [userAuth, setUserAuth ] = useState(false)

  return (
    <div className="App">
      <Router>
        <Switch>
          {
            !userAuth
              ? (
                  <Route path='/'>
                    <Login setUserAuth = { setUserAuth } />
                  </Route>
                )
              : (
                  <>
                    <Route exact path='/'>
                      <Evaluation setUserAuth = { setUserAuth }/>
                    </Route>
                    <Route exact path='/participants'>
                      <Participants />
                    </Route>
                    <Route exact path='/karma'>
                      <Karma />
                    </Route>
                  </>
              )
          }
        </Switch>
      </Router>
    </div>
  );
}

export default App;