import React, {useEffect} from 'react'
import UserCard from '../../components/UserCard/UserCard'

import './UserContainer.scss'

function UserContainer({ title, usersObject, actionHandler, buttonText, btnClass }){
        
    useEffect(()=>{
        console.log('user object:',usersObject)
    },[])

    const userContainers = Object.keys(usersObject).map(userKey=>{
        let userName = usersObject[userKey].name
        let companyName = usersObject[userKey].company
        
        return(
            <UserCard 
                name = { userName } 
                company = { companyName } 
                id={ userKey } 
                actionHandler = { actionHandler }  
                buttonText = { buttonText }
                btnClass = { btnClass }
            />
        )
    })
        
        


    return(
        <div className="user-block">
            <h2>{ title }</h2>
            { userContainers }
        </div>
    )
}

export default UserContainer